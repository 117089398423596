import useGetUsersForApplicationAndCompany from "./useGetUsersForApplicationAndCompany"
import useGetUsersForGroup from "./useGetUsersForGroup"
import _ from "lodash"

export const useGetGroupMembers = (applicationId: number, companyId: number, groupId: number) => {
	const {
		users: applicationUsers,
		loading: applicationUsersLoading,
		refetchData: refetchUsersForApplicationAndCompany,
	} = useGetUsersForApplicationAndCompany(applicationId, companyId)
	const { groupUsers, loading: groupUsersLoading, refetchData: refetchUsersForGroup } = useGetUsersForGroup(applicationId, companyId, groupId)

	// We need to split the users into two arrays
	// those who are in the group, and those who aren't

	const [inGroup, notGrouped] = _.partition(applicationUsers, applicationUser =>
		groupUsers.some(groupUser => groupUser.key === applicationUser.userId)
	)

    const refetch = () => {
        refetchUsersForApplicationAndCompany()
        refetchUsersForGroup()
    }

	return {
		refetch,
		applicationUsersLoading,
		groupUsersLoading,
		inGroup,
		notGrouped,
	}
}
