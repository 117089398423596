import useGetCompanies from "../../api/hooks/useGetCompanies"
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams, GridToolbar } from "@mui/x-data-grid"
import { Box, Button, Container, Grid } from "@mui/material"
import { Link } from "react-router-dom"

const Companies = () => {
	const companies = useGetCompanies()

	const columns: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 1,
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
			renderCell: (params: GridCellParams) => (
				<Link to={`${params.row.id}/groups`}>
					<Button>Groups</Button>
				</Link>
			),
		},
	]

	return (
		<>
			<Box style={{ backgroundColor: "#F0E9F3" }}>
				<Container fixed>
					<Grid container style={{ paddingTop: 100 }}>
						<Grid item xs={12} className="title">
							Members Portal
						</Grid>
						<Grid item xs={12} className="subtitle">
							Companies
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container style={{ height: 650, marginTop: 50, marginBottom: 50 }}>
				<DataGrid
					rows={companies}
					columns={columns}
					disableColumnFilter
					disableColumnSelector
					disableDensitySelector
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							printOptions: { disableToolbarButton: true },
							csvOptions: { disableToolbarButton: true },
						},
					}}
					disableRowSelectionOnClick
					pageSizeOptions={[10]}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
					}}
				/>
			</Container>
		</>
	)
}

export default Companies
