import { useCallback, useEffect, useState } from "react"
import { useAccount, useMsal } from "@azure/msal-react"
import { AxiosError } from "axios"
import agent from "../agent"
import msalInstance, { protectedResources } from "../../authconfig"
import CompanyGroup from "../../models/CompanyGroup"

/**
 * Custom hook to return the list of groups for a company from the admin portal API.
 *
 * Current implementation handles JWT acquisition within the hook, no need
 * to call msalInstance.acquireTokenSilent() independently
 */
const useGetGroupsForCompany = (applicationId: number | null | false, companyId: number | false) => {
	const [groups, setGroups] = useState<CompanyGroup[] | null>(null)
	const { accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) ?? undefined

	const fetchData = useCallback(async () => {
		if (!companyId || !applicationId) {
			setGroups(null)
			return
		}

		const controller = new AbortController()
		if (account) {
			;(async () => {
				const token = await msalInstance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})
				try {
					const groupInfo = await agent.application.getGroupsForCompany(
						applicationId,
						companyId,
						token.accessToken,
						controller
					)

					setGroups(groupInfo)
				} catch (error) {
					const axiError = error as AxiosError
					if (axiError.code === "ERR_CANCELED") return
					else console.error(axiError)
				}
			})()
		}
		return () => controller.abort()
	}, [account, setGroups, applicationId, companyId])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const refetchData = useCallback(() => {
		fetchData()
	}, [fetchData])

	return { groups, refetchData }
}

export default useGetGroupsForCompany
