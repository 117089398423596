import { useState } from "react"
import { useAccount, useMsal } from "@azure/msal-react"
import { AxiosError } from "axios"
import agent from "../agent"
import msalInstance, { protectedResources } from "../../authconfig"
import { INPLUser } from "../../Interfaces"

/**
 * Custom hook to remove a user from a group using the admin portal API.
 * Users are passed as INPLUser objects
 *
 * Current implementation handles JWT acquisition within the hook, no need
 * to call msalInstance.acquireTokenSilent() independently
 */
const useRemoveUserFromGroup = (applicationId: number, companyId: number, groupId: number) => {
	const [loading, setLoading] = useState<boolean>(false)
	const { accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) || undefined

	const removeUser = async (user: INPLUser) => {
		if (account) {
			setLoading(true)
			try {
				const { accessToken } = await msalInstance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})
				setLoading(true)

				const { userId } = user
				await agent.application.removeUserFromGroup(applicationId, companyId, groupId, userId, accessToken)
			} catch (error) {
				const axiError = error as AxiosError
				if (axiError.code === "ERR_CANCELED") return
				else console.error(axiError)
			} finally {
				setLoading(false)
			}
		}
	}

	return { removeUser, loading }
}

export default useRemoveUserFromGroup
