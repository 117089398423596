import { useAccount, useMsal } from "@azure/msal-react"
import { useContext, useEffect, useState } from "react"
import { protectedResources } from "../authconfig"
import { Box, Button, CircularProgress, Container, Grid, Stack, Typography } from "@mui/material"
import { UserContext } from "../UserContext"
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid"
import { Link } from "react-router-dom"
import "../css/site.css"
import "../css/application.css"
import { INPLApp } from "../Interfaces"
import agent from "../api/agent"

export default function SiteManagement() {
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) || undefined
	const { user } = useContext(UserContext)

	const [applications, setApplications] = useState<INPLApp[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		document.title = "Application Management - NPL Account Portal"
	}, [])

	useEffect(() => {
		;(async () => {
			if (account) {
				const token = await instance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})

				const apps = await agent.application.getAll(token.accessToken)

				// Filter the list of applications to only the ones that the user has access to
				const userApps = apps.filter(application => {
					if (!user.userApplications) {
						return false
					}
					return user.userApplications.some(
						userApp =>
							userApp.roleName === "NPL_ADMIN" && userApp.applicationId === application.applicationId
					)
				})

				setApplications(userApps)
				setIsLoading(false)
			}
		})()
	}, [account, instance, user.userApplications])

	const columns: GridColDef[] = [
		{ field: "applicationName", headerName: "Application", flex: 1 },
		{
			field: "actions",
			headerName: "Actions",
			width: 250,
			renderCell: (params: GridCellParams) => {
				return (
					<Stack direction="row">
						<Link to={`${params.row.applicationId}`}>
							<Button variant="contained" color="primary" size="small">
								Users
							</Button>
						</Link>
					</Stack>
				)
			},
		},
	]

	const titleTextWhenNotLoading = applications.length ? <>Application Administration</> : <></>

	return (
		<div>
			<Box className="layout">
				<Container fixed>
					<Grid container className="titlePadding">
						<Grid item xs={12} className="title">
							Members Portal
						</Grid>
						<Grid item xs={12} className="subtitle">
							{isLoading ? <>Loading</> : titleTextWhenNotLoading}
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Container className="middleContainer">
				{!applications.length ? (
					<Box sx={{ m: 2, height: 600, width: "100%" }}>
						<Typography variant="body1" marginLeft="50%">
							Fetching your applications
						</Typography>
						<CircularProgress sx={{ marginTop: "10px", marginLeft: "50%" }} />
					</Box>
				) : (
					<Box sx={{ m: 2, height: 600, width: "100%" }}>
						<DataGrid
							rows={applications}
							columns={columns}
							loading={isLoading}
							disableRowSelectionOnClick
							getRowId={(row: INPLApp) => row.applicationId}
							pageSizeOptions={[10]}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
						/>
					</Box>
				)}
			</Container>
		</div>
	)
}
