import axios, { AxiosResponse } from "axios"
import type {
	ICompany,
	INPLApp,
	INPLUser,
	IRegistrationApplication,
	IRole,
	IUserRegistration,
	Registration,
} from "../Interfaces"
import CompanyGroup, { CompanyGroupFormSubmission, CompanyGroupUpdateSubmission } from "../models/CompanyGroup"

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const responseBody = <T>(response: AxiosResponse<T>) => response.data

const requests = {
	get: <T>(url: string) => axios.get<T>(url).then(responseBody),
	getWithToken: <T>(url: string, accessToken: string, controller?: AbortController) =>
		axios
			.get<T>(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
				signal: controller?.signal,
			})
			.then(responseBody),
	post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
	postWithToken: (url: string, body: {}, accessToken: string) =>
		axios
			.post(url, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then(responseBody),
	postWithTokenFull: (url: string, body: {}, accessToken: string, controller?: AbortController) =>
		axios.post(url, body, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			signal: controller?.signal,
		}),
	put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
	putWithToken: (url: string, body: {}, accessToken: string) =>
		axios
			.put(url, body, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			}),
	del: (url: string) => axios.delete(url).then(responseBody),
	delWithToken: (url: string, body: {}, accessToken: string) =>
		axios
			.delete(url, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then(responseBody),
	delWithTokenFull: (url: string, body: {}, accessToken: string) =>
		axios.delete(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
		}),
}

const application = {
	getAll: (accessToken: string, controller?: AbortController) =>
		requests.getWithToken<INPLApp[]>("/Application", accessToken, controller),
	getAppRoles: (applicationId: number, accessToken: string) =>
		requests.getWithToken<IRole[]>(`/Application/${applicationId}/Roles`, accessToken),
	getApplication: (id: number, accessToken: string) =>
		requests.getWithToken<INPLApp>(`/Application/${id}`, accessToken),
	getGroupsForCompany: (
		applicationId: number,
		companyId: number,
		accessToken: string,
		controller?: AbortController
	) =>
		requests.getWithToken<CompanyGroup[]>(
			`/Application/${applicationId}/Company/${companyId}/Groups`,
			accessToken,
			controller
		),
	createGroupForCompany: (
		applicationId: number | string,
		companyId: number | string,
		group: CompanyGroupFormSubmission,
		accessToken: string,
		controller?: AbortController
	) =>
		requests.postWithTokenFull(
			`/Application/${applicationId}/Company/${companyId}/Groups`,
			group,
			accessToken,
			controller
		),
	updateGroupForCompany: (
		applicationId: number | string,
		companyId: number | string,
		group: CompanyGroupUpdateSubmission,
		accessToken: string,
		controller?: AbortController
	) =>
		requests.postWithTokenFull(
			`/Application/${applicationId}/Company/${companyId}/Groups`,
			group,
			accessToken,
			controller
		),
	addUserToGroup: (
		applicationId: number,
		companyId: number,
		groupId: number,
		userIds: string[],
		accessToken: string,
		controller?: AbortController
	) =>
		requests.postWithTokenFull(
			`/Application/${applicationId}/Company/${companyId}/Groups/${groupId}`,
			userIds,
			accessToken,
			controller
		),
	removeUserFromGroup: (
		applicationId: number,
		companyId: number,
		groupId: number,
		userId: string,
		accessToken: string
	) =>
		requests.delWithTokenFull(
			`/Application/${applicationId}/Company/${companyId}/Groups/${groupId}/User/${userId}`,
			{},
			accessToken
		),
}

const details = {
	getCompanies: (accessToken: string, controller?: AbortController) =>
		requests.getWithToken<ICompany[]>("/User/GetCompanies", accessToken, controller),
}

const user = {
	applicationUsers: (applicationId: number, accessToken: string, controller?: AbortController) =>
		requests.getWithToken<INPLUser[]>(`/User/${applicationId}`, accessToken, controller),
	getUser: (accessToken: string) => requests.getWithToken<INPLUser>(`/User/GetUserForWeb/`, accessToken),
	approveUser: (user: IRegistrationApplication, accessToken: string) =>
		requests.postWithTokenFull(`/User/ApproveUser`, user, accessToken),
	updateUser: (user: IUserRegistration, accessToken: string) => requests.postWithToken(`/User`, user, accessToken),
	submitAppRegistration: (registration: Registration, accessToken: string) =>
		requests.postWithToken(`/User/${registration.userId}`, registration, accessToken),
	setUserRole: (user: IRegistrationApplication, accessToken: string) =>
		requests.postWithToken(`/User/${user.userId}/Roles`, user, accessToken),
	revokeUser: ( userId: string, applicationId: number, accessToken: string) =>
		requests.putWithToken(`/User/RevokeUser/${userId}/Application/${applicationId}`, {}, accessToken),
}

const agent = {
	application,
	details,
	user,
}

export default agent
