import { object, string } from "yup";
import { keyValuePair } from "../Interfaces";

type CompanyGroup = {
    id: number;
    name: string;
    description: string;
    companyId: number;
    companyName: string;
    applicationId: number | string;
    applicationName: string;
    users: keyValuePair[]
}

export type CompanyGroupFormSubmission = Pick<CompanyGroup, "name" | "description" | "applicationId">

export type CompanyGroupUpdateSubmission = Pick<CompanyGroup, "name" | "description" | "applicationId" | "id">

export const companyGroupValidationSchema = object().shape({
    name: string().required('Name is required'),
    description: string().required('Description is required'),
    applicationId: string().required("Company is required")
})

export default CompanyGroup