import { useEffect, useState, useCallback } from "react"
import { useAccount, useMsal } from "@azure/msal-react"
import { AxiosError } from "axios"
import agent from "../agent"
import msalInstance, { protectedResources } from "../../authconfig"
import { INPLUser } from "../../Interfaces"

/**
 * Custom hook to return the list of users for an application from a specific company
 * from the admin portal API.
 *
 * Current implementation handles JWT acquisition within the hook, no need
 * to call msalInstance.acquireTokenSilent() independently
 */
const useGetUsersForApplicationAndCompany = (applicationId: number, companyId: number) => {
	const [users, setUsers] = useState<INPLUser[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const { accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) || undefined

	const fetchData = useCallback(async () => {
		const controller = new AbortController()
		if (account) {
			setLoading(true)
			try {
				const { accessToken } = await msalInstance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})
				const userInfo = await agent.user.applicationUsers(applicationId, accessToken, controller)
				setUsers([...userInfo].filter(user => user.companyId === companyId))
			} catch (error) {
				const axiError = error as AxiosError
				if (axiError.code === "ERR_CANCELED") return
				else console.error(axiError)
			} finally {
				setLoading(false)
			}
		}
		return () => controller.abort()
	}, [account, applicationId, companyId])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const refetchData = useCallback(() => {
		fetchData()
	}, [fetchData])

	return { users, loading, refetchData }
}

export default useGetUsersForApplicationAndCompany
