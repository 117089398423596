import { useEffect, useState } from "react"
import { useAccount, useMsal } from "@azure/msal-react"
import { AxiosError } from "axios"
import agent from "../agent"
import msalInstance, { protectedResources } from "../../authconfig"
import { ICompany } from "../../Interfaces"

/**
 * Custom hook to return the list of companies from the admin portal API.
 *
 * Current implementation handles JWT acquisition within the hook, no need
 * to call msalInstance.acquireTokenSilent() independently
 */
const useGetCompanies = () => {
	const [companies, setCompanies] = useState<ICompany[]>([])
	const { accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) || undefined
	useEffect(() => {
		const controller = new AbortController()
		if (account) {
			;(async () => {
				const token = await msalInstance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})
				try {
					const companyInfo = await agent.details.getCompanies(token.accessToken, controller)

					setCompanies(
						[...companyInfo].sort((a, b) => {
							return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
						})
					)
				} catch (error) {
					const axiError = error as AxiosError
					if (axiError.code === "ERR_CANCELED") return
					else console.error(axiError)
				}
			})()
		}
		return () => controller.abort()
	}, [account, setCompanies])

	return companies
}

export default useGetCompanies
