import { useCallback, useEffect, useState } from "react"
import { useAccount, useMsal } from "@azure/msal-react"
import { AxiosError } from "axios"
import agent from "../agent"
import msalInstance, { protectedResources } from "../../authconfig"
import { keyValuePair } from "../../Interfaces"

/**
 * Custom hook to return the list of users for a specific company group from the admin portal API.
 *
 * Current implementation handles JWT acquisition within the hook, no need
 * to call msalInstance.acquireTokenSilent() independently
 */
const useGetUsersForGroup = (applicationId: number | null | false, companyId: number | false, groupId: number) => {
	const [groupUsers, setGroupUsers] = useState<keyValuePair[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const { accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) ?? undefined

	const fetchData = useCallback(async () => {
		const controller = new AbortController()

		if (!companyId || !applicationId) {
			setGroupUsers([])
			setLoading(false)
			return
		}

		if (account) {
			setLoading(true)
			try {
				const { accessToken } = await msalInstance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})

				const groupInfo = await agent.application.getGroupsForCompany(
					applicationId,
					companyId,
					accessToken,
					controller
				)

				// Find for the required group's users
				const groupUsers = groupInfo.find(group => group.id === groupId)?.users

				if (groupUsers) {
					setGroupUsers(groupUsers)
				}
			} catch (error) {
				const axiError = error as AxiosError
				if (axiError.code === "ERR_CANCELED") return
				else console.error(axiError)
			} finally {
				setLoading(false)
			}

			return () => controller.abort()
		}
	}, [account, applicationId, companyId, groupId])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	const refetchData = useCallback(() => {
		fetchData()
	}, [fetchData])

	return { groupUsers, loading, refetchData }
}

export default useGetUsersForGroup
