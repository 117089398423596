import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid"
import {
	Box,
	Button,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
} from "@mui/material"
import { Link, useParams } from "react-router-dom"
import useGetGroupsForCompany from "../../../api/hooks/useGetGroupsForCompany"
import { useContext, useState } from "react"
import { UserContext } from "../../../UserContext"

const CompanyGroupList = () => {
	const { companyId } = useParams()
	const numCompanyId = !!companyId && parseInt(companyId)
	const [appId, setAppId] = useState<string>("")
	const { user } = useContext(UserContext)

	const { groups } = useGetGroupsForCompany(parseInt(appId), numCompanyId)

	const apps = user.userApplications?.filter(app => app.roleName === "NPL_ADMIN")

	if (!companyId) return <p>No company ID was provided</p>

	const columns: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
		},
		{
			field: "description",
			headerName: "Description",
			flex: 1,
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.5,
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
			renderCell: (params: GridCellParams) => (
				<Link to={`${params.row.id}/application/${appId}`}>
					<Button>View</Button>
				</Link>
			),
		},
	]

	const handleAppChange = (event: SelectChangeEvent) => {
		setAppId(event.target.value)
	}

	return (
		<>
			<Box style={{ backgroundColor: "#F0E9F3" }}>
				<Container fixed>
					<Grid container style={{ paddingTop: 100 }}>
						<Grid item xs={12} className="title">
							Members Portal
						</Grid>
						<Grid item xs={12} className="subtitle">
							Groups
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container style={{ height: 600, paddingBottom: 100 }}>
				<Stack
					direction="row"
					width="100%"
					style={{ paddingTop: 20, paddingBottom: 20 }}
					justifyContent="space-between"
				>
					{apps && !!apps.length && (
						<FormControl style={{ width: "75%" }}>
							<InputLabel id="application-selector-label">Application</InputLabel>
							<Select
								labelId="application-selector-label"
								value={appId}
								label="Application"
								onChange={handleAppChange}
							>
								{apps.map(app => (
									<MenuItem key={app.applicationId} value={app.applicationId}>
										{app.applicationName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<Link to={`create`} style={{ justifySelf: "flex-end" }}>
						<Button style={{ margin: 0 }} variant="contained" disabled={!groups}>
							Add group
						</Button>
					</Link>
				</Stack>

				<DataGrid
					loading={!!groups && !groups}
					rows={groups ?? []}
					columns={columns}
					pageSizeOptions={[10]}
					disableRowSelectionOnClick
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 10,
							},
						},
					}}
				/>
			</Container>
		</>
	)
}

export default CompanyGroupList
