import { CompanyGroupUpdateSubmission, companyGroupValidationSchema } from "../../../models/CompanyGroup"
import {
	Box,
	Button,
	Container,
	FormControlLabel,
	Grid,
	TextField,
	Stack,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material"
import { useFormik } from "formik"
import useGetCompanies from "../../../api/hooks/useGetCompanies"
import { useNavigate, useParams } from "react-router-dom"
import { protectedResources } from "../../../authconfig"
import { useAccount, useMsal } from "@azure/msal-react"
import agent from "../../../api/agent"
import { ChangeEvent, useContext, useState } from "react"
import { UserContext } from "../../../UserContext"
import useGetGroupsForCompany from "../../../api/hooks/useGetGroupsForCompany"
import { SwitchWithText } from "../../../components/SwitchWithText"
import ManageGroupUserForm from "../../../components/CompanyGroup/ManageGroupUserForm"

const ManageGroup = () => {
	const { user } = useContext(UserContext)
	const companies = useGetCompanies()
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) ?? undefined
	const { companyId, applicationId, groupId } = useParams()
	const [formDisabled, setFormDisabled] = useState<boolean>(true)

	const apps = user.userApplications?.filter(app => app.roleName === "NPL_ADMIN")
	const numCompanyId = !!companyId && parseInt(companyId)
	const numApplicationId = !!applicationId && parseInt(applicationId)
	const numGroupId = !!groupId && parseInt(groupId)
	const { groups, refetchData } = useGetGroupsForCompany(numApplicationId, numCompanyId)
	const selectedGroup = groups?.find(group => group.id === numGroupId)

	const groupForm = useFormik({
		initialValues: {
			name: selectedGroup ? selectedGroup.name : "",
			description: selectedGroup ? selectedGroup.description : "",
			applicationId: selectedGroup ? selectedGroup.applicationId : "",
		},
		enableReinitialize: true,
		validationSchema: companyGroupValidationSchema,
		onSubmit: async values => {
			if (companyId && account) {
				const { accessToken } = await instance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})

				if (!selectedCompany) throw new Error(`Selected company (company ID: ${companyId} not found`)
				if (!selectedGroup) throw new Error(`Selected group (group ID: ${groupId}) not found`)

				const valuesToSubmit: CompanyGroupUpdateSubmission = { ...values, id: selectedGroup.id }

				const { applicationId } = valuesToSubmit

				const response = await agent.application.createGroupForCompany(
					applicationId,
					companyId,
					valuesToSubmit,
					accessToken
				)
				if (response.status === 200) {
					setFormDisabled(true)
					refetchData();
				}
			}
		},
	})

	if (!companyId) return <p>Error in the path</p>
	if (!companies.length) return <CircularProgress />
	if (!apps) return <CircularProgress />
	const selectedCompany = companies.find(company => company.id === parseInt(companyId))
	if (!selectedCompany) return <p>Company name not found</p>

	const handleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		setFormDisabled(!event.target.checked)
	}

	return (
		<>
			<Box style={{ backgroundColor: "#F0E9F3" }}>
				<Container fixed>
					<Grid container style={{ paddingTop: 100 }}>
						<Grid item xs={12} className="title">
							Members Portal
						</Grid>
						<Grid item xs={12} className="subtitle">
							Manage groups {`for ${selectedCompany.name}`}
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container style={{ paddingTop: 40 }}>
				<form onSubmit={groupForm.handleSubmit}>
					<Stack spacing={2}>
						<FormControlLabel
							label="Edit group"
							control={
								<SwitchWithText
									checked={!formDisabled}
									onChange={handleSwitch}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
							sx={{ alignSelf: "flex-end" }}
						/>
						<FormControl style={{ margin: 10 }} fullWidth>
							<InputLabel id="application-selector-label">Application</InputLabel>
							<Select
								labelId="application-selector-label"
								{...groupForm.getFieldProps("applicationId")}
								label="Application"
								disabled={formDisabled}
							>
								{apps.map(app => (
									<MenuItem key={app.applicationId} value={app.applicationId}>
										{app.applicationName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField
							label="Group name"
							fullWidth
							multiline
							disabled={formDisabled}
							variant="outlined"
							{...groupForm.getFieldProps("name")}
							error={groupForm.touched.name && Boolean(groupForm.errors.name)}
							helperText={groupForm.touched.name && groupForm.errors.name}
						/>

						<TextField
							label="Description"
							fullWidth
							multiline
							disabled={formDisabled}
							rows={4}
							variant="outlined"
							{...groupForm.getFieldProps("description")}
							error={groupForm.touched.description && Boolean(groupForm.errors.description)}
							helperText={groupForm.touched.description && groupForm.errors.description}
						/>

						<Container style={{ padding: 0 }}>
							<Button type="submit" variant="contained" color="primary" disabled={formDisabled}>
								Update
							</Button>
						</Container>
					</Stack>
				</form>
			</Container>
			{!!numGroupId && !!numApplicationId && !!numCompanyId && (
				<ManageGroupUserForm applicationId={numApplicationId} companyId={numCompanyId} groupId={numGroupId} />
			)}
		</>
	)
}
export default ManageGroup
