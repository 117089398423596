import { Button, Container, Stack, Typography } from "@mui/material/"
import { DataGrid, GridCellParams, GridColDef, GridColumnHeaderParams, useGridApiRef } from "@mui/x-data-grid"
import { INPLUser } from "../../Interfaces"
import useAddUsersToGroup from "../../api/hooks/useAddUsersToGroup"
import { useGetGroupMembers } from "../../api/hooks/useGetGroupMembers"
import useRemoveUserFromGroup from "../../api/hooks/useRemoveUserFromGroup"

type Props = {
	applicationId: number
	companyId: number
	groupId: number
}

const ManageGroupUserForm = ({ applicationId, companyId, groupId }: Props) => {
	const applicationUserGridRef = useGridApiRef()
	const groupUserGridRef = useGridApiRef()
	const { submitUsers, loading: postingUsers } = useAddUsersToGroup(applicationId, companyId, groupId)
	const { removeUser } = useRemoveUserFromGroup(applicationId, companyId, groupId)
	const { groupUsersLoading, applicationUsersLoading, inGroup, notGrouped, refetch } = useGetGroupMembers(
		applicationId,
		companyId,
		groupId
	)

	const addUsersToGroup = async () => {
		const userList = Array.from(applicationUserGridRef.current.getSelectedRows().keys()).map(userId =>
			userId.toString()
		)
		await submitUsers(userList)
		refetch()
	}

	const removeUserFromGroup = async (user: INPLUser) => {
		console.log("We would be removing this user", user)

		await removeUser(user);

		refetch()
	}

	const userColumns: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			flex: 0.5,
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
			valueGetter: (params: GridCellParams) => `${params.row.firstName} ${params.row.surname}`,
		},
		{
			field: "email",
			headerName: "Email",
			flex: 0.8,
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
		},
	]

	const groupUserColumns: GridColDef[] = [
		...userColumns,
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.4,
			align: "left",
			renderHeader: (params: GridColumnHeaderParams) => <strong>{params.colDef.headerName}</strong>,
			renderCell: (params: GridCellParams) => (
				<Button variant="contained" style={{ margin: 0 }} onClick={() => removeUserFromGroup(params.row)}>
					Remove
				</Button>
			),
		},
	]

	return (
		<Container style={{ paddingBottom: 80, minHeight: 600 }}>
			<Typography variant="h5">Application Users</Typography>
			<Stack direction="row" gap={2} style={{ height: 600, marginTop: 20 }}>
				<DataGrid
					apiRef={applicationUserGridRef}
					columns={userColumns}
					rows={notGrouped}
					getRowId={(row: INPLUser) => row.userId}
					loading={applicationUsersLoading || groupUsersLoading}
					checkboxSelection
				/>
				<Stack direction="column" alignItems={"center"} justifyContent={"space-evenly"}>
					<Button variant="contained" onClick={() => addUsersToGroup()}>
						Add selected users
					</Button>
				</Stack>
				<DataGrid
					apiRef={groupUserGridRef}
					columns={groupUserColumns}
					rows={inGroup}
					getRowId={(row: INPLUser) => row.userId}
					loading={applicationUsersLoading || groupUsersLoading || postingUsers}
				/>
			</Stack>
		</Container>
	)
}

export default ManageGroupUserForm
