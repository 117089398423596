import { useAccount, useMsal } from "@azure/msal-react"
import { useCallback, useEffect, useState } from "react"
import { protectedResources } from "../authconfig"
import {
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	MenuItem,
	Stack,
	TextField,
} from "@mui/material"
import "../css/site.css"
import "../css/application.css"
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid"
import { INPLApp, INPLUser, IRegistrationApplication, IRole, IUserRegistration } from "../Interfaces"
import { useNavigate, useParams } from "react-router-dom"
import agent from "../api/agent"
import { Field, Form, Formik } from "formik"
import InitialsIcon from "../components/InitialsIcon"
import { ApprovalDialog, DeniedDialog } from "../components/Dialog"

interface DialogProps {
	open: boolean
	applicationId: number
	applicationRoles: IRole[]
	selectedValue: INPLUser
	onClose: (value: INPLUser, reloadPage: boolean) => void
}

function UserEditDialog(props: DialogProps) {
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) || undefined

	const roleForApp =
		props.selectedValue?.userApplications?.find(obj => obj.applicationId === props.applicationId) ??
		({} as IRegistrationApplication)

	const { onClose, selectedValue, open } = props

	const handleClose = () => onClose(selectedValue, true)
	const handleCloseNoUpdate = () => onClose(selectedValue, false)

	return (
		<Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
			<Formik
				initialValues={{ selectedRoleId: roleForApp?.roleId } as IUserRegistration}
				enableReinitialize={true}
				onSubmit={(values, formikHelpers) => {
					formikHelpers.setSubmitting(false)

					instance
						.acquireTokenSilent({
							scopes: protectedResources.apiNPLTime.scopes,
							account: account,
						})
						.then(response => {
							let updateUser: IRegistrationApplication = {
								userId: props.selectedValue.userId,
								applicationId: props.applicationId,
								roleId: values.selectedRoleId ?? 0,
							}

							agent.user.setUserRole(updateUser, response.accessToken).finally(() => handleClose())
						})
				}}
			>
				{({ values, submitForm }) => {
					return (
						<>
							<DialogTitle>Change role of {selectedValue.displayName}</DialogTitle>
							<DialogContent>
								<DialogContentText>
									<Form>
										<Field name="selectedRoleId" as={TextField} select fullWidth label="Role">
											{props.applicationRoles &&
												props.applicationRoles.map((e, key) => {
													return (
														<MenuItem key={key} value={e.id}>
															{e.name}
														</MenuItem>
													)
												})}
										</Field>
									</Form>
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button autoFocus color="secondary" onClick={handleCloseNoUpdate}>
									Close
								</Button>
								<Button color="primary" onClick={submitForm}>
									Save
								</Button>
							</DialogActions>
						</>
					)
				}}
			</Formik>
		</Dialog>
	)
}

interface RevokeDialogProps {
	open: boolean
	selectedValue: INPLUser
	onAccept: () => void;
	onClose: () => void;
}

function ConfirmRevokeDialog({ open, onAccept, onClose, selectedValue }: RevokeDialogProps) {
	return (
		<Dialog
			fullWidth
			maxWidth="xs"
			open={open}
			onClose={onClose}
			aria-labelledby="revoke-dialog-title"
			aria-describedby="revoke-dialog-description"
		>
			<DialogTitle id="revoke-dialog-title">Are you sure?</DialogTitle>
			<DialogContent>
				<DialogContentText id="revoke-dialog-description">
					{`Are you sure you want to revoke user ${selectedValue.firstName} ${selectedValue.surname}?`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={onClose}>
					No
				</Button>
				<Button variant="contained" onClick={onAccept}>
					Yes
				</Button>
			</DialogActions>
		</Dialog>);
}

export default function UserManagement() {
	const navigate = useNavigate()
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) || undefined
	const { app_id } = useParams()

	const [currentApplication, setApplication] = useState<INPLApp>()
	const [roleOptions, setRoleOptions] = useState<IRole[]>([])
	const [appUsers, setAppUsers] = useState<INPLUser[]>([])
	const [isLoading, setLoading] = useState<boolean>(true)

	const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
	const [openApprovalDialog, setApprovalDialog] = useState<boolean>(false)
	const [openRevokeDialog, setOpenRevokeDialog] = useState<boolean>(false)
	const [openDeniedDialog, setDeniedDialog] = useState<boolean>(false)
	const [userSelected, setUserSelected] = useState<INPLUser>({} as INPLUser)

	const handleClickEditOpen = () => {
		setOpenEditDialog(true)
	}

	const handleCloseRevoke = () => {
		setOpenRevokeDialog(false)
		setUserSelected({} as INPLUser)
	}

	const handleEditClose = (value: any, reloadPage: boolean = true) => {
		setOpenEditDialog(false)
		setUserSelected(value)
		if (reloadPage) {
			reloadUserList()
		}
	}

	const handleCloseApprovalDialog = () => setApprovalDialog(false)

	const reloadUserList = useCallback(() => {
		setLoading(true)
		instance
			.acquireTokenSilent({
				scopes: protectedResources.apiNPLTime.scopes,
				account: account,
			})
			.then(response => {
				if (app_id !== undefined) {
					agent.user.applicationUsers(parseInt(app_id), response.accessToken).then(innerResponse => {
						setAppUsers(innerResponse)
						setLoading(false)
					})
				}
			})
	}, [account, instance, app_id])

	useEffect(() => {
		currentApplication
			? (document.title = `${currentApplication.applicationName} Users - NPL Account Portal`)
			: (document.title = "Users - NPL Account Portal")
	}, [currentApplication])

	useEffect(() => {
		;(async () => {
			if (account) {
				const token = await instance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})

				if (app_id !== undefined) {
					// Get all app users ...
					reloadUserList()

					// ... and all the possible roles ...
					const roles = await agent.application.getAppRoles(parseInt(app_id), token.accessToken)
					setRoleOptions(roles)

					// ... and finally the selected app details.
					const appDetails = (await agent.application.getAll(token.accessToken)).find(
						app => app.applicationId === parseInt(app_id)
					)
					setApplication(appDetails)
				} else {
					// App doesn't exist.
					navigate("/app")
				}
			}
		})()
	}, [account, instance, app_id, navigate, reloadUserList])

	const columns: GridColDef[] = [
		{
			field: "avatar",
			headerName: "",
			width: 60,
			renderCell: (params: GridCellParams) => (
				<InitialsIcon name={`${params.row.firstName} ${params.row.surname}`} />
			),
		},
		{
			field: "name",
			headerName: "Name",
			flex: 0.8,
			renderCell: (params: GridCellParams) => `${params.row.firstName} ${params.row.surname}`,
		},
		{ field: "email", headerName: "Email", flex: 1 },
		{ field: "companyName", headerName: "Company", flex: 1 },
		{
			field: "appRoles",
			headerName: "Role",
			flex: 0.5,
			renderCell: (params: GridCellParams) => {
				if (params.row.userApplications === null) {
					return <>Awaiting Approval</>
				}

				let roleText: string = ""
				if (params.row.userApplications !== null) {
					params.row.userApplications.forEach((e: IRegistrationApplication) => {
						if (e.applicationId === parseInt(app_id ?? "0")) {
							roleText = e.roleName ?? "No Role Assigned"
						}
					})
				}

				return <>{roleText}</>
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 1,
			renderCell: (params: GridCellParams) => {
				if (app_id !== undefined && params.row.userApplications !== null) {
					const roleForApp = (params.row as INPLUser).userApplications.find(
						obj => obj.applicationId === parseInt(app_id)
					)

					if (roleForApp === undefined) {
						return <></>
					}

					return (
						<Stack direction="row">
							{roleForApp.roleId === 0 && (
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => {
										setUserSelected(params.row)
										setApprovalDialog(true)
									}}
								>
									Approve & Assign
								</Button>
							)}
							{roleForApp.roleId > 0 && (
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => {
										setUserSelected(params.row)
										handleClickEditOpen()
									}}
								>
									Change Role
								</Button>
							)}
							<Button
								variant="contained"
								color="primary"
								size="small"
								onClick={() => {
									setUserSelected(params.row)
									setOpenRevokeDialog(true)
								}}
							>
								Revoke
							</Button>
						</Stack>
					)
				} else {
					return <></>
				}
			},
		},
	]

	return (
		<div>
			<Box className="layout">
				<Container fixed>
					<Grid container className="titlePadding">
						<Grid item xs={12} className="title">
							Members Portal
						</Grid>
						<Grid item xs={12} className="subtitle">
							{currentApplication?.applicationName} Users
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Container className="middleContainer">
				<Box sx={{ m: 2, height: 600, width: "100%" }}>
					<DataGrid
						rows={appUsers}
						columns={columns}
						getRowId={(e: INPLUser) => e.userId}
						loading={isLoading}
						disableRowSelectionOnClick
						pageSizeOptions={[10]}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 10,
								},
							},
						}}
					/>
				</Box>
			</Container>

			<UserEditDialog
				applicationId={parseInt(app_id ?? "0")}
				applicationRoles={roleOptions}
				selectedValue={userSelected}
				open={openEditDialog}
				onClose={handleEditClose}
			/>

			<ApprovalDialog
				title={userSelected.displayName ?? ""}
				open={openApprovalDialog}
				onClose={handleCloseApprovalDialog}
				onConfirm={async () => {
					if (account) {
						const token = await instance.acquireTokenSilent({
							scopes: protectedResources.apiNPLTime.scopes,
							account: account,
						})

						const acceptedTerms = userSelected.userApplications.find(
							app => app.applicationId === parseInt(app_id ?? "0")
						)?.hasUserAcceptedTsCs

						if (typeof acceptedTerms === "undefined")
							throw new Error(
								"hasUserAcceptedTsCs is undefined, or the object containing it was undefined"
							)

						if (!currentApplication) return

						const userApplicationData: IRegistrationApplication = {
							userId: userSelected.userId,
							applicationId: currentApplication.applicationId,
							applicationName: currentApplication.applicationName,
							roleId: roleOptions[0].id,
							roleName: roleOptions[0].name,
							hasUserAcceptedTsCs: acceptedTerms,
						}

						try {
							const response = await agent.user.approveUser(userApplicationData, token.accessToken)
							if (response.status === 200) {
								await agent.user.setUserRole(userApplicationData, token.accessToken)
								reloadUserList()
							}
						} catch (err) {
							const error = err as any
							if (error.response && error.response.status === 401) setDeniedDialog(true)
						}
					}

					setApprovalDialog(false)
				}}
			/>

			<DeniedDialog open={openDeniedDialog} onClose={() => setDeniedDialog(false)} />
			<ConfirmRevokeDialog
				open={openRevokeDialog}
				selectedValue={userSelected}
				onClose={handleCloseRevoke}
				onAccept={async () => {
					if (account && currentApplication) {
						const token = await instance
							.acquireTokenSilent({
								scopes: protectedResources.apiNPLTime.scopes,
								account: account,
							})
						try {
							const response = await agent.user.revokeUser(userSelected.userId, currentApplication.applicationId, token.accessToken)
							if (response.status === 200) {
								reloadUserList()
							}
						} catch (err) {
							const error = err as any
							if (error.response && error.response.status === 401) setDeniedDialog(true)
						}
					}
					handleCloseRevoke()
				}
				}
			/>
		</div>
	)
}
