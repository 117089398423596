import { CompanyGroupFormSubmission, companyGroupValidationSchema } from "../../../models/CompanyGroup"
import {
	Box,
	Button,
	Container,
	Grid,
	TextField,
	Stack,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@mui/material"
import { useFormik } from "formik"
import useGetCompanies from "../../../api/hooks/useGetCompanies"
import { useNavigate, useParams } from "react-router-dom"
import { protectedResources } from "../../../authconfig"
import { useAccount, useMsal } from "@azure/msal-react"
import agent from "../../../api/agent"
import { useContext } from "react"
import { UserContext } from "../../../UserContext"

const CreateGroup = () => {
	const { user } = useContext(UserContext)
	const companies = useGetCompanies()
	const { instance, accounts } = useMsal()
	const account = useAccount(accounts[0] || {}) ?? undefined
	const { companyId } = useParams()
	const navigate = useNavigate()

	const apps = user.userApplications.filter(app => app.roleName === "NPL_ADMIN")

	const formik = useFormik({
		initialValues: {
			name: "",
			description: "",
			applicationId: "",
		},
		validationSchema: companyGroupValidationSchema,
		onSubmit: async values => {
			if (companyId && account) {
				const { accessToken } = await instance.acquireTokenSilent({
					scopes: protectedResources.apiNPLTime.scopes,
					account: account,
				})

				if (!selectedCompany) throw new Error(`Selected company (company ID: ${companyId} not found`)

				const valuesToSubmit: CompanyGroupFormSubmission = values

				const { applicationId } = valuesToSubmit

				const response = await agent.application.createGroupForCompany(
					applicationId,
					companyId,
					valuesToSubmit,
					accessToken
				)
				if (response.status === 200) {
					console.log("Group created")

					const { id: groupId } = response.data
					navigate(`/companies/${companyId}/groups/${groupId}/application/${applicationId}`)
				}
			}
		},
	})

	if (!companyId) return <p>Error in the path</p>
	if (!companies.length) return <CircularProgress />
	if (!apps) return <CircularProgress />

	const selectedCompany = companies.find(company => company.id === parseInt(companyId))

	if (!selectedCompany) return <p>Company name not found</p>

	return (
		<>
			<Box style={{ backgroundColor: "#F0E9F3" }}>
				<Container fixed>
					<Grid container style={{ paddingTop: 100 }}>
						<Grid item xs={12} className="title">
							Members Portal
						</Grid>
						<Grid item xs={12} className="subtitle">
							Create Group {`for ${selectedCompany.name}`}
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container style={{ height: 600, paddingBottom: 80, paddingTop: 40 }}>
				<form onSubmit={formik.handleSubmit}>
					<Stack spacing={2}>
						<FormControl style={{ margin: 10 }} fullWidth>
							<InputLabel id="application-selector-label">Application</InputLabel>
							<Select
								labelId="application-selector-label"
								{...formik.getFieldProps("applicationId")}
								label="Application"
							>
								{apps.map(app => (
									<MenuItem key={app.applicationId} value={app.applicationId}>
										{app.applicationName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField
							label="Group name"
							fullWidth
							multiline
							variant="outlined"
							{...formik.getFieldProps("name")}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>

						<TextField
							label="Description"
							fullWidth
							multiline
							rows={4}
							variant="outlined"
							{...formik.getFieldProps("description")}
							error={formik.touched.description && Boolean(formik.errors.description)}
							helperText={formik.touched.description && formik.errors.description}
						/>

						<Container>
							<Button type="submit" variant="contained" color="primary">
								Submit
							</Button>
						</Container>
					</Stack>
				</form>
			</Container>
		</>
	)
}
export default CreateGroup
